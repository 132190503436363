import gql from 'graphql-tag'


export const CREATE_LINK = (templateType) => gql`mutation CREATE_LINK ($docType: String!, $parentId: Int!, $childId: Int!) {
  createLink: createDoc${templateType}Link (docType: $docType, parentId: $parentId, childId: $childId)
}`

export const DESTROY_LINK = (templateType) => gql`mutation DESTROY_LINK ($docType: String!, $parentId: Int!, $childId: Int!) {
  destroyLink: destroyDoc${templateType}Link (docType: $docType, parentId: $parentId, childId: $childId)
}`

export const LIST_BILLABLE = (templateType) => gql`query LIST_BILLABLE ($docType: String!, $q: FilterInput) {
  docs: listDoc${templateType}OrderAvailableInvoice (docType: $docType, q: $q) {
    id type code name remark
    contactId totalPrice billedAt
  }
}`

export const LIST_ORDER_AVAILABLE_INVOICE = (templateType) => gql`query LIST_ORDER_AVAILABLE_INVOICE ($docType: String!, $docId: Int, $q: FilterInput) {
  docs: listDoc${templateType}OrderAvailableInvoice (docType: $docType, docId: $docId, q: $q) {
    id type code name remark
    contactId totalPrice billedAt
  }
}`

export const LIST_ORDER_AVAILABLE_CREDIT_NOTE = (templateType) => gql`query LIST_ORDER_AVAILABLE_CREDIT_NOTE ($docType: String!, $docId: Int!, $q: FilterInput) {
  docs: listDoc${templateType}OrderAvailableCreditNote (docType: $docType, docId: $docId, q: $q) {
    id type code name remark
    contactId totalPrice remaining
    approvedBy closedBy
  }
}`

export const LIST_ORDER_AVAILABLE_DEBIT_NOTE = (templateType) => gql`query LIST_ORDER_AVAILABLE_DEBIT_NOTE ($docType: String!, $docId: Int!, $q: FilterInput) {
  docs: listDoc${templateType}OrderAvailableDebitNote (docType: $docType, docId: $docId, q: $q) {
    id type code name remark
    contactId totalPrice remaining
    approvedBy closedBy
  }
}`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  invoices: listDoc${templateType}OrderInvoice (docType: $docType, docId: $docId) {
    id type code name totalPrice
    billedAt approvedAt closedAt dueAt
  }
  creditNotes: listDoc${templateType}OrderCreditNote (docType: $docType, docId: $docId) {
    id type code name
    totalPrice remaining closedAt
  }
  debitNotes: listDoc${templateType}OrderDebitNote (docType: $docType, docId: $docId) {
    id type code name
    totalPrice remaining closedAt
  }
}`
